<template>
  <div>
    <nav-box></nav-box>
    <div class="w">
      <div class="list-box">
        <!--<div class="lf-box">
          <ul>
            <li @click="details">采购公告</li>
            <li @click="details">询比价公告</li>
            <li @click="details">公司公告</li>
            <li @click="details">中标公告</li>
            <li @click="details">供应商处罚公告</li>
          </ul>
        </div>-->
        <div class="rt-box">
          <!--<div class="search">
            <span>标题</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
            <el-button icon="el-icon-search"></el-button>
            <span>时间</span>
            <el-time-picker
              is-range
              v-model="value1"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围">
            </el-time-picker>
            <el-button icon="el-icon-search"></el-button>
          </div>-->
          <el-table
            :data="tableData"
            stripe
            style="width: 100%">
            <el-table-column prop="date" label="公告类型">
              <template scope="scope">
                <div @click="details(scope.row)" class="col-box">询价公告</div>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="标题"></el-table-column>
            <el-table-column prop="date" label="时间">
              <div>2020-08-28</div>
            </el-table-column>
          </el-table>
          <el-pagination
            style="margin-top: 20px"
            :page-sizes="[15, 20]"
            :page-size="15"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <qq-footer></qq-footer>
  </div>
</template>

<script>
import NavBox from '@/components/common/navBox'
import QqFooter from '@/components/common/qqFooter'

export default {
  components: { QqFooter, NavBox },
  data () {
    return {
      tableData: [
        {
          title: '不锈钢焊条询价公告',
          level: 1
        },
        {
          title: '内六角螺栓询价公告',
          level: 2
        },
        {
          title: '螺栓询价公告',
          level: 3
        }
      ],
      input: '',
      value1: '',
      total: 10
    }
  },
  methods: {
    details (row) {
      sessionStorage.setItem('level', row.level)
      this.$router.push('/InquiryDetails')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-table .cell {
  text-align: center;
  font-weight: normal;
}
.col-box {
  color: blue;
  cursor: pointer;
}
.list-box {
  width: 100%;
  display: flex;
  .lf-box {
    flex: 4;
    width: 20%;
    height: 278px;
    border: 1px dashed #ccc;
    margin-top: 140px;
    ul {
      padding: 0;
      margin: 0;
      li {
        line-height: 50px;
        text-align: center;
        border-bottom: 1px solid #F0F3FA;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          cursor: pointer;
          color: blue;
        }
      }
    }
  }
  .rt-box {
    padding: 0 20px;
    flex: 20;
    .el-input {
      width: 289px;
      margin: 0 10px;
    }
    .el-button {
      margin: 0 10px;
    }
    .search {
      margin-bottom: 10px;
      >span {
        margin: 0 10px;
        font-size: 16px;
        color: #969896;
      }
    }
  }
}
ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.w {
  width: 1200px;
  margin: 0 auto;
}
</style>
